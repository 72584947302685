/* eslint-disable no-underscore-dangle */
import { PasskeyOnboardingPresentingProps } from '@app/features/passkey/types';

import { isNullOrUndefined } from '@app/common/utils';

import { LocalStorageDocument } from '@app/core/storage/browser/LocalStorageDocument';
import { Migration } from '@app/core/versionedSerializer/versionedSerializer';

const CURRENT_VERSION = 1;
const NODE_KEY = 'passkey.onboarding';

/**
 * Inner serialization interface for data saved by LoginLocalSettings.
 */
interface IPasskeyOnboardingLocalStorage extends PasskeyOnboardingPresentingProps {}

/**
 * Singleton class for managing login settings stored in local storage.
 */
export class PasskeyOnboardingLocalStorage extends LocalStorageDocument<IPasskeyOnboardingLocalStorage> {
  /**
   * Singleton methods
   */
  private static inner?: PasskeyOnboardingLocalStorage;

  /**
   * Constructor must describe latest data version and provide migrations from previous versions of saved data.
   * @param node string node key to storage data in local storage.
   * @param version latest version.
   * @param migrations migrations from previous versions to latest.
   */
  private constructor(node: string, version: number, migrations: Migration[] = []) {
    super(node, version, migrations);

    if (isNullOrUndefined(this.lastSkip)) {
      this.skipCount = 0;
    }
  }

  public static get instance(): PasskeyOnboardingLocalStorage {
    if (!this.inner) {
      this.inner = new PasskeyOnboardingLocalStorage(NODE_KEY, CURRENT_VERSION);
    }

    return this.inner;
  }

  /**
   * Public interface methods
   */

  private _lastSkip!: Date;

  private _skipCount!: number;

  public deferralPeriodInDays = 7;

  public maxDeferralTresholdInDays = 30;

  public get lastSkip() {
    return this._lastSkip;
  }

  public set lastSkip(value: Date) {
    this._lastSkip = value;
    // Persist new value on change.
    this.save();
  }

  public get skipCount() {
    return this._skipCount;
  }

  public set skipCount(value: number) {
    this._skipCount = value;
    // Persist new value on change.
    this.save();
  }

  public get passkeyOnboardingPresentingProps(): PasskeyOnboardingPresentingProps {
    return {
      lastSkip: this.lastSkip,
      skipCount: this.skipCount,
      deferralPeriodInDays: this.deferralPeriodInDays,
      maxDeferralTresholdInDays: this.maxDeferralTresholdInDays,
    };
  }

  /**
   * Abstract methods implementation
   */

  /**
   * Save public data properties into serializable data structure.
   * @returns data structure which can be saved in local storage.
   */
  protected serialize(): IPasskeyOnboardingLocalStorage {
    return {
      lastSkip: this.lastSkip,
      skipCount: this.skipCount,
      deferralPeriodInDays: this.deferralPeriodInDays,
      maxDeferralTresholdInDays: this.maxDeferralTresholdInDays,
    };
  }

  /**
   * Init public data properties with saved data.
   * @param deserialized saved data.
   */
  protected deserialize(deserialized: IPasskeyOnboardingLocalStorage) {
    this.lastSkip = deserialized.lastSkip;
    this.skipCount = deserialized.skipCount;
    this.deferralPeriodInDays = deserialized.deferralPeriodInDays;
    this.maxDeferralTresholdInDays = deserialized.maxDeferralTresholdInDays;
  }
}
