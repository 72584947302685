import { PasskeyOnboardingPresentingProps } from './types';

export const getNextDateToPresentPasskeyOnbording = ({
  lastSkip,
  skipCount,
  deferralPeriodInDays,
  maxDeferralTresholdInDays,
} : PasskeyOnboardingPresentingProps) => {
  const nextDateTimeToShow = new Date(lastSkip);
  const daysToAdd = skipCount * deferralPeriodInDays;
  const maxDays = Math.min(daysToAdd, maxDeferralTresholdInDays);
  nextDateTimeToShow.setDate(nextDateTimeToShow.getDate() + maxDays);
  return nextDateTimeToShow;
};

export const isPasskeyOnbordingDeferral = (
  { currentDate, ...restProps } : PasskeyOnboardingPresentingProps & { currentDate?: Date },
) => {
  // If user has never skipped the onboarding, always show it.
  if (!restProps.lastSkip || !restProps.skipCount) {
    return false;
  }

  // otherwise, calculate the next show date and compare it with the current date.
  const nextPresentDate = getNextDateToPresentPasskeyOnbording(restProps);
  return (currentDate ?? new Date()) <= nextPresentDate;
};

export const shouldShowPasskeyOnboarding = (
  isLoginWithPasskey: boolean,
  isPasskeyEnabled: boolean,
  passkeyOnboardingPresentingProps: PasskeyOnboardingPresentingProps,
  userEligibleForPasskey?: boolean,
) => (
  !isLoginWithPasskey
    && isPasskeyEnabled
    && !!userEligibleForPasskey
    && !isPasskeyOnbordingDeferral(passkeyOnboardingPresentingProps)
);
